import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; 
import Navbar from './Navbar';
import Footer from './Footer';

const HomePage = () => {
  const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    // Function to determine the video source
    const determineVideoSource = () => {
      if (window.innerWidth <= 768) {
        console.log('Mobile video loaded');
        return '/Assets/Mobile Header.mp4'; // Mobile video
      } else {
        console.log('Desktop video loaded');
        return '/Assets/Header Video New.mp4'; // Desktop video
      }
    };

    // Set the initial video source
    setVideoSrc(determineVideoSource());

    // Handle resize events
    const handleResize = () => {
      setVideoSrc(determineVideoSource());
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!videoSrc) {
    // Optionally handle the case where videoSrc is not yet set
    return null; // Or a loading spinner if desired
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Home - Vertex Global Holding Inc.</title>
      </Helmet>
      <Navbar />
      <div className="pageBg h-full flex-grow flex items-center justify-center">
        <div className="relative bg-white w-full  md:h-[80vh] lg:h-[100vh] overflow-hidden">
          <video
            className="md:w-full h-full object-cover"
            autoPlay
            loop
            muted
            preload="metadata"
            poster="/Assets/Main Landing Page.png"
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
