import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactUs = () => {
  return (
    <div className="pageBg text-white flex flex-col w-full overflow-y-auto">
      <Helmet>
        <title>Contact Us - Vertex Global Holding Inc.</title>
      </Helmet>
      <Navbar />
      
      {/* Image Section */}
      <motion.div 
        className="relative flex flex-col w-full h-[calc(100vh-130px)]"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 3 }}
        whileHover={{scale:1.09, ease:'easeInOut'}}
      >
        <img
          src="/Assets/ContactUs.png"
          alt="Contact Us"
          className="object-cover w-full h-full"
        />
      </motion.div>

      <div className="flex flex-col items-center w-full px-4 md:px-0">
        {/* Text Section */}
        <motion.div
          className="text-center mt-4 mb-8 max-w-6xl"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <p className="text-xs md:text-xl leading-loose">
            We would love to hear from you!<br/>
            Whether you have inquiries, want to discuss a potential project, or simply need guidance,<br/>
            our friendly and knowledgeable team is here to assist you.
          </p>
          <p className="mt-4 text-lg md:text-xl font-bold">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
            Vertex Global Holding Inc
          </p>
          <p className="mt-2 text-sm md:text-base">
            <div
              
            >
              250-997 Seymour Street, Vancouver BC V6B3MA
            </div>
          </p>
          <p className="mt-2 text-sm md:text-base">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Email: <a href="mailto:info@vertexglobal.co" className="underline hover:text-gray-300 hover:font-bold">info@vertexglobal.co</a>
          </p>
        </motion.div>

        {/* Google Map Section */}
        
      </div>
      
      <Footer />
    </div>
  );
}

export default ContactUs;
