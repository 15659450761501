import React from 'react';
import Navbar from './Navbar';
import { Link as ScrollLink, Element } from 'react-scroll';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import Footer from './Footer';

// Function to render rings and images based on section
const renderSectionContent = (section, controls, imgSrc, circleSrc) => {
  const sectionsConfig = {
    "cast-crew": {
      smallCircleLeft: { width: 200, height: 200, left: '-10%', top: '10%' },
      smallCircleRight: { width: 250, height: 250, right: '-10%', bottom: '10%' },
      bigCircle: { width: 700, height: 700 },
      imgSize: { width: 500, height: 500 },
    },
    "sound-equipment": {
      smallCircleLeft: { width: 180, height: 180, left: '-10%', top: '10%' },
      smallCircleRight: { width: 220, height: 220, right: '-10%', bottom: '10%' },
      bigCircle: { width: 700, height: 700 },
      imgSize: { width: 500, height: 500 },
    },
    "props-set-construction": {
      smallCircleLeft: { width: 200, height: 200, left: '-10%', top: '10%' },
      smallCircleRight: { width: 250, height: 250, right: '-10%', bottom: '10%' },
      bigCircle: { width: 700, height: 700 },
      imgSize: { width: 500, height: 500 },
    },
  };

  const config = sectionsConfig[section];

  return (
    <>
      {/* Small Rings */}
      <motion.img
        src="/Assets/Small Circle.png"
        alt="Small Circle"  
        className={`absolute object-contain opacity-50`}
        style={{
          width: `${config.smallCircleLeft.width}px`,
          height: `${config.smallCircleLeft.height}px`,
          left: config.smallCircleLeft.left,
          top: config.smallCircleLeft.top,
        }}
        animate={controls}
        whileHover={{
          rotate: 360,
          scale: 1.1,
          transition: { duration: 1, ease: "easeInOut" },
        }}
      />
      <motion.img
        src="/Assets/Small Circle.png"
        alt="Small Circle"
        className={`absolute object-contain opacity-50`}
        style={{
          width: `${config.smallCircleRight.width}px`,
          height: `${config.smallCircleRight.height}px`,
          right: config.smallCircleRight.right,
          bottom: config.smallCircleRight.bottom,
        }}
        animate={controls}
        whileHover={{
          rotate: -360,
          scale: 1.1,
          shadow: '20px',
          transition: { duration: 1, ease: "easeInOut" },
        }}
      />

      {/* Central Image with Big Circle */}
      <div
        className="relative flex justify-center items-center"
        style={{
          width: `${config.bigCircle.width}px`,
          height: `${config.bigCircle.height}px`,
        }}
      >
        <motion.img
          src="/Assets/Big Circle.png"
          alt="Big Circle"
          className="absolute w-full h-full object-contain"
          whileHover={{
            rotate: [0, 15, 0],
            transition: { duration: 0.5, ease: "easeInOut" },
          }}
        />
        <motion.img
          src={imgSrc}
          alt={section}
          className="absolute rounded-full object-cover scale-90"
          style={{
            width: `${config.imgSize.width}px`,
            height: `${config.imgSize.height}px`,
            top: `calc(50% - ${config.imgSize.height / 2}px)`,
            left: `calc(50% - ${config.imgSize.width / 2}px)`,
          }}
          initial={{ scale: 0.9 }}
          whileHover={{ scale: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        />
      </div>
    </>
  );
};

const PreProductionPage = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeInOut" },
    });
  }, [controls]);

  return (
    <div className="pageBg xl:flex flex-col items-center justify-center pt-14 min-h-screen w-full text-white overflow-x-hidden">
      <Navbar />

      {/* Fixed Navigation Bar */}
      {/* <div className="fixed top-[72px] hidden left-0 right-0 z-20 bg-transparent lg:flex justify-center">
        <div className="flex flex-wrap justify-center  space-x-2 md:space-x-4 p-4">
          <ScrollLink to="cast-crew" smooth={true} duration={1000}>
            <motion.div
              className="w-[100px] h-[40px] md:w-[150px] md:h-[50px] relative"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <img
                src="/Assets/Tab.png"
                alt="Tab"
                className="w-full h-full object-cover"
              />
              <motion.button
                className="absolute inset-0 flex items-center justify-center text-white text-xs md:text-base"
                whileHover={{ scale: 1.1 }}
              >
                Cast & Crew
              </motion.button>
            </motion.div>
          </ScrollLink>
          <ScrollLink to="sound-equipment" smooth={true} duration={1000}>
            <motion.div
              className="w-[100px] h-[40px] md:w-[150px] md:h-[50px] relative"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <img
                src="/Assets/Tab.png"
                alt="Tab"
                className="w-full h-full object-cover"
              />
              <motion.button
                className="absolute inset-0 flex items-center justify-center text-white  text-xs md:text-base"
                whileHover={{ scale: 1.1 }}
              >
                Sound Equipment
              </motion.button>
            </motion.div>
          </ScrollLink>
          <ScrollLink to="props-set-construction" smooth={true} duration={1000}>
            <motion.div
              className="w-[100px] h-[40px] md:w-[150px] md:h-[50px] relative"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <img
                src="/Assets/Tab.png"
                alt="Tab"
                className="w-full h-full object-cover"
              />
              <motion.button
                className="absolute inset-0 flex items-center justify-center text-white text-xs md:text-base"
                whileHover={{ scale: 1.1 }}
              >
                Props & Set
              </motion.button>
            </motion.div>
          </ScrollLink>
        </div>
      </div> */}

      {/* Cast & Crew Section */}
      <Element name="cast-crew">
        <motion.div
          className="flex flex-col md:flex-row items-center justify-center w-full min-h-screen lg:max-w-7xl xl:max-w-full py-12 relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {renderSectionContent(
            "cast-crew",
            controls,
            "/Assets/Cast & Crew Image.png",
            "/Assets/Big Circle.png"
          )}
          <div className="w-full md:w-1/2 px-4 md:px-10 z-10">
            <motion.h2
              className="text-xl md:text-2xl font-bold mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Cast & Crew :
            </motion.h2>
            <motion.p
              className="text-xs md:text-sm leading-relaxed mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.2 }}
            >
              <strong>Talent Search & Auditions</strong>
              <br />
              We scout and find the best actors and actresses suited for the roles in your project and organize auditions to assess and select the most suitable candidates.
            </motion.p>
            <motion.p
              className="text-xs md:text-sm leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.4 }}
            >
              <strong>Heads of Departments and Skilled Crew</strong>
              <br />
              Provide HODs, including directors of photography, production designers, costume designers, and others. Also, supply experienced crew members such as camera operators, lighting technicians, sound recordists, and production assistants to support your production.
            </motion.p>
          </div>
        </motion.div>
      </Element>

      {/* Sound Equipment Section */}
      <Element name="sound-equipment">
        <motion.div
          className="flex flex-col md:flex-row items-center justify-center w-full min-h-screen max-w-7xl xl:max-w-full py-12 relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {renderSectionContent(
            "sound-equipment",
            controls,
            "/Assets/Sound Equipments.png",
            "/Assets/Big Circle.png"
          )}
          <div className="w-full md:w-1/2 px-4 md:px-10 z-10">
            <motion.h2
              className="text-xl md:text-2xl font-bold mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Sound Equipment
            </motion.h2>
            <motion.p
              className="text-xs md:text-sm leading-relaxed mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.2 }}
            >
              <strong>High-Quality Microphones</strong>
              <br />
              Provide a variety of microphones suitable for different recording environments and scenes.
            </motion.p>
            <motion.p
              className="text-xs md:text-sm leading-relaxed mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.4 }}
            >
              <strong>Mixers and Recorders</strong>
              <br />
              Supply professional audio mixers and recorders to capture and control sound levels.
            </motion.p>
            <motion.p
              className="text-xs md:text-sm leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.6 }}
            >
              <strong>Technical Support</strong>
              <br />
              Provide on-site technical support for setup and troubleshooting of sound equipment.
            </motion.p>
          </div>
        </motion.div>
      </Element>

      {/* Props & Set Construction Section */}
      <Element name="props-set-construction">
        <motion.div
          className="flex flex-col md:flex-row items-center justify-center w-full min-h-screen max-w-7xl py-12 relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {renderSectionContent(
            "props-set-construction",
            controls,
            "/Assets/Set Construction.png",
            "/Assets/Big Circle.png"
          )}
          <div className="w-full md:w-1/2 px-4 md:px-10 z-10">
            <motion.h2
              className="text-xl md:text-2xl font-bold mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Props & Set Construction
            </motion.h2>
            <motion.p
              className="text-xs md:text-sm leading-relaxed mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.2 }}
            >
              <strong>Set Material & Construction</strong>
              <br />
              Provide materials and designs for realistic, visually appealing backdrops, furnish sets with furniture and decor; supply wood, paint, tools and scenic elements like greenery and architectural features. Construct and assemble safe, stable, and accurate sets, with on-site support and maintenance during the shoot.
            </motion.p>
            <motion.p
              className="text-xs md:text-sm leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.4 }}
            >
              <strong>Prop Sourcing & Custom Build</strong>
              <br />
              Assist in sourcing props that match the creative vision of your project, from everyday items to unique pieces. Design and build custom props tailored to specific needs and scenes.
            </motion.p>
          </div>
        </motion.div>
      </Element>
      <Footer />
    </div>
  );
};

export default PreProductionPage;
